import React, { useState, useEffect } from "react";
import i18next from "i18next";
import EmbeddedOrganizationDetails from "./EmbeddedOrganizationDetails";
import EmbeddedCharityDetails from "./EmbeddedCharityDetails";
import EmbeddedHowOften from "./EmbeddedHowOften";
import FeeScreen from "./FeeScreen";
import AddInformation from "./AddInformation";
import SuccessMsgScreen from "./SuccessMsgScreen";

const EmbeddedOrganization = ({
  hostName,
  userData,
  organizationDetails,
  charityDetails,
  setCharityDetails,
  step,
  setStep,
  paths,
  vTag,
  currentQuery,
  quantity,
  setQuantity,
}) => {
  // states

  // amount
  const [amount, setAmount] = useState(0);
  const [newAmount, setNewAmount] = useState(parseFloat(amount));
  const [amountValid, setAmountValid] = useState(false);

  // one-time / recurring
  const [howOften, setHowOften] = useState("ONE-TIME");
  const [recurringValue, setRecurringValue] = useState(null);
  const [validRecurring, setValidRecurring] = useState(true);
  const [specifyTime, setSpecifyTime] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  // fees
  const [fees, setFees] = useState(0);
  const [isChecked, setIsChecked] = useState(false);

  // payment
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardHolderEmail, setCardHolderEmail] = useState("");

  // success
  const [successMsg, setSuccessMsg] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  // language code
  const [lang, setLang] = useState();
  useEffect(() => {
    const language = localStorage.getItem("i18nextLng");
    if (language) {
      setLang(language);
    }
  }, [lang]);

  const handleLanguage = (id) => {
    setLang(id);
    i18next.changeLanguage(id);
  };

  return (
    <div className={userData ? "main-container logged-in" : "main-container"}>
      <div className="dashboard-container">
        {step === 0 && (
          <EmbeddedOrganizationDetails
            organizationDetails={organizationDetails}
            setCharityDetails={setCharityDetails}
            step={step}
            setStep={setStep}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            currentQuery={currentQuery}
            setQuantity={setQuantity}
            setAmount={setAmount}
          />
        )}
        {step === 1 && (
          <EmbeddedCharityDetails
            organizationDetails={organizationDetails}
            step={step}
            setStep={setStep}
            amount={amount}
            setAmount={setAmount}
            amountValid={amountValid}
            setAmountValid={setAmountValid}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            currentQuery={currentQuery}
            quantity={quantity}
            setQuantity={setQuantity}
            charityDetails={charityDetails}
          />
        )}
        {step === 2 && !charityDetails.enableQuantity && (
          <EmbeddedHowOften
            organizationDetails={organizationDetails}
            step={step}
            setStep={setStep}
            amount={amount}
            setAmount={setAmount}
            amountValid={amountValid}
            setAmountValid={setAmountValid}
            howOften={howOften}
            setHowOften={setHowOften}
            recurringValue={recurringValue}
            setRecurringValue={setRecurringValue}
            validRecurring={validRecurring}
            setValidRecurring={setValidRecurring}
            specifyTime={specifyTime}
            setSpecifyTime={setSpecifyTime}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            currentQuery={currentQuery}
            startDate={startDate}
            setStartDate={setStartDate}
            charityDetails={charityDetails}
          />
        )}
        {step === 3 && !charityDetails.enableQuantity && (
          <FeeScreen
            organizationDetails={organizationDetails}
            step={step}
            setStep={setStep}
            amount={amount}
            newAmount={newAmount}
            setNewAmount={setNewAmount}
            amountValid={amountValid}
            validRecurring={validRecurring}
            fees={fees}
            setFees={setFees}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            currentQuery={currentQuery}
            charityDetails={charityDetails}
          />
        )}
        {step === 4 && (
          <AddInformation
            hostName={hostName}
            organizationDetails={organizationDetails}
            step={step}
            setStep={setStep}
            amount={amount}
            newAmount={newAmount}
            setNewAmount={setNewAmount}
            amountValid={amountValid}
            howOften={howOften}
            setHowOften={setHowOften}
            recurringValue={recurringValue}
            validRecurring={validRecurring}
            specifyTime={specifyTime}
            fees={fees}
            setFees={setFees}
            isChecked={isChecked}
            cardHolderName={cardHolderName}
            setCardHolderName={setCardHolderName}
            cardHolderEmail={cardHolderEmail}
            setCardHolderEmail={setCardHolderEmail}
            setSuccessMsg={setSuccessMsg}
            setPaymentSuccess={setPaymentSuccess}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            vTag={vTag}
            currentQuery={currentQuery}
            startDate={startDate}
            quantity={quantity}
            charityDetails={charityDetails}
          />
        )}
        {step === 5 && (
          <SuccessMsgScreen
            organizationDetails={organizationDetails}
            setStep={setStep}
            amount={amount}
            newAmount={newAmount}
            howOften={howOften}
            recurringValue={recurringValue}
            specifyTime={specifyTime}
            fees={fees}
            cardHolderName={cardHolderName}
            cardHolderEmail={cardHolderEmail}
            successMsg={successMsg}
            paymentSuccess={paymentSuccess}
            lang={lang}
            handleLanguage={handleLanguage}
            paths={paths}
            currentQuery={currentQuery}
            startDate={startDate}
            quantity={quantity}
            charityDetails={charityDetails}
          />
        )}
      </div>
    </div>
  );
};

export default EmbeddedOrganization;
