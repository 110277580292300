/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-icons-kit";
import { arrows_left } from "react-icons-kit/linea/arrows_left";
import { arrows_right } from "react-icons-kit/linea/arrows_right";
import { basic_lock } from "react-icons-kit/linea/basic_lock";
import logo from "../../images/logo.png";
import { useNavigate } from "react-router-dom";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

export const EmbeddedCharityDetails = ({
  organizationDetails,
  step,
  setStep,
  amount,
  setAmount,
  amountValid,
  setAmountValid,
  lang,
  handleLanguage,
  paths,
  currentQuery,
  quantity,
  setQuantity,
  charityDetails,
}) => {
  // translation
  const { t } = useTranslation();

  // track amount and set amount valid boolean
  useEffect(() => {
    setAmountValid(
      parseFloat(amount) >= parseFloat(charityDetails.minimumAmount) &&
        amount !== ""
    );
  }, [amount]);

  // handle amount btns
  const handleAmount = (e) => {
    if (e.currentTarget.id) {
      setAmount(parseFloat(e.currentTarget.id));
    }
  };

  // handle custom amount
  const handleCustomAmount = () => {
    setAmount(0);
  };

  // custom amount active
  const [customAmountActive, setCustomAmountActive] = useState(false);
  useEffect(() => {
    if (
      parseFloat(amount) !== 10 &&
      parseFloat(amount) !== 25 &&
      parseFloat(amount) !== 50 &&
      parseFloat(amount) !== 100 &&
      parseFloat(amount) !== 250
    ) {
      setCustomAmountActive(true);
    } else {
      setCustomAmountActive(false);
    }
  }, [amount]);

  // handle input onchange amount
  const handleDonationAmount = (value) => {
    if (value < 0) {
      value = 0;
    }
    setAmount(value);
  };

  // handle continue
  const handleContinue = () => {
    if (charityDetails.enableQuantity) {
      if (isQuantityValid) {
        setStep(4);
      }
    } else {
      if (amountValid) {
        setStep(step + 1);
      }
    }
  };

  // percentage
  const [percentage, setPercentage] = useState(null);
  const [width, setWidth] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  useEffect(() => {
    if (charityDetails) {
      const calculatedPercentage = (
        (charityDetails.raisedAmount / charityDetails.targetAmount) *
        100
      ).toFixed(2);
      const calculatedWidth =
        calculatedPercentage >= 100 || !isFinite(calculatedPercentage)
          ? "100%"
          : `${calculatedPercentage}%`;
      const calculatedBackgroundColor = "#007c16";

      setPercentage(calculatedPercentage);
      setWidth(calculatedWidth);
      setBackgroundColor(calculatedBackgroundColor);
    }
  }, [charityDetails]);

  // navigate
  const navigate = useNavigate();

  // handle back step
  const handleBackStep = (step) => {
    setStep(step - 1);
    if (currentQuery) {
      navigate(`/${paths[0]}/${paths[1]}${currentQuery}`, { replace: true });
    } else {
      navigate(`/${paths[0]}/${paths[1]}`, { replace: true });
    }
  };

  // handle quantity
  const handleQuantity = (value) => {
    // Allow only numbers between 1 and 999
    if (/^\d+$/.test(value) && value >= 1 && value <= 999) {
      setQuantity(value);
    } else if (value === "") {
      setQuantity(""); // Allow clearing the input
    }
  };

  // is qty valid
  const isQuantityValid =
    parseInt(quantity) >= 1 && parseInt(quantity) <= 999 && quantity !== "";

  // continue btn style
  const buttonStyle = charityDetails.enableQuantity
    ? {
        pointerEvents: isQuantityValid ? "auto" : "none",
        opacity: isQuantityValid ? 1 : 0.5,
      }
    : {
        pointerEvents: amountValid ? "auto" : "none",
        opacity: amountValid ? 1 : 0.5,
      };

  return (
    <div className="home-content">
      <div className="embed-charity-details-container">
        <header className="embed-charity-details-header">
          <div onClick={() => handleBackStep(step)} className="arrow-left-span">
            <Icon icon={arrows_left} size={32} />
          </div>
          <h5 className="choose-amount">
            {charityDetails.enableQuantity ? (
              <>{t("CampaignDetails.choose_quantity")}</>
            ) : (
              <>{t("CampaignDetails.choose_amount")}</>
            )}
          </h5>
        </header>

        <div className="embed-logo-container">
          <div className="embed-campaign-logo">
            <img src={charityDetails.icon.filename} alt="card-logo" />
          </div>
          <div className="embed-charity-organization-name">
            <div className="embed-charity-name">{charityDetails.name}</div>
            <div className="embed-organization-name">
              {organizationDetails.name}
            </div>
          </div>
        </div>

        {charityDetails.targetAmount > 0 && (
          <>
            {width && backgroundColor && (
              <div className="campaign-details-progress-div">
                <div
                  className="progress-bar"
                  style={{
                    width,
                    background: backgroundColor,
                  }}
                ></div>
              </div>
            )}
          </>
        )}

        {charityDetails.targetAmount > 0 && (
          <div className="campaign-collection">
            <div className="collection-box">
              <h4>
                {organizationDetails.currencySymbol}
                {charityDetails.raisedAmount.toFixed(2)}
              </h4>
              <h6>{t("CampaignDetails.raised")}</h6>
            </div>

            <div className="collection-box">
              {percentage && (
                <>
                  <h4>
                    {percentage >= 100 || !isFinite(percentage)
                      ? 100
                      : percentage}
                    %
                  </h4>
                  <h6>{t("CampaignDetails.collection")}</h6>
                </>
              )}
            </div>
            <div className="collection-box last">
              <h4>
                {organizationDetails.currencySymbol}
                {charityDetails.targetAmount.toFixed(2)}
              </h4>
              <h6>{t("CampaignDetails.target")}</h6>
            </div>
          </div>
        )}

        <p className="embed-charity-msg">{charityDetails.description}</p>

        {charityDetails.enableQuantity ? (
          <MDBRow
            className="mb-3"
            style={{
              margin: 0,
              padding: 0,
              overflow: "hidden",
            }}
          >
            <MDBCol
              className="custom-quantity-col col-12 col-md-4 mb-2 mb-md-0"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <h4 style={{ width: "300px" }}>
                {t("CampaignDetails.cost_per_unit")}
              </h4>
              <div className="big-input-div">
                <div className="dollar">
                  {organizationDetails.currencySymbol}
                </div>
                <input
                  value={charityDetails.minimumAmount}
                  readOnly
                  style={{ width: "100%" }}
                />
              </div>
            </MDBCol>
            <MDBCol
              className="custom-quantity-col col-12 col-md-4 mb-2 mb-md-0"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <h4 style={{ width: "300px" }}>
                {t("CampaignDetails.enter_quantity")}
              </h4>
              <div className="big-input-div">
                <input
                  type="number"
                  inputMode="numeric"
                  step="1"
                  required
                  value={quantity}
                  onChange={(e) => handleQuantity(e.target.value)}
                  style={{ width: "100%" }}
                />
              </div>
            </MDBCol>
            <MDBCol
              className="custom-quantity-col col-12 col-md-4 mb-2 mb-md-0"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <h4 style={{ width: "300px" }}>
                {t("DonationModal.total_amount")}
              </h4>
              <div className="big-input-div">
                <div className="dollar">
                  {organizationDetails.currencySymbol}
                </div>
                <input
                  value={parseFloat(charityDetails.minimumAmount) * quantity}
                  readOnly
                  style={{ width: "100%" }}
                />
              </div>
            </MDBCol>
          </MDBRow>
        ) : (
          <div className="input-and-minimum-amount-msg-container">
            <div className="big-input-div">
              <div className="dollar">{organizationDetails.currencySymbol}</div>
              <input
                type="number"
                inputMode="decimal"
                step="0.01"
                required
                value={amount}
                onChange={(e) => handleDonationAmount(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
            <span
              className={`minimum-amount-msg embed${amountValid ? "" : " red"}`}
            >
              {t("CampaignDetails.minimum_amount")}{" "}
              {organizationDetails.currencySymbol}
              {charityDetails.minimumAmount}
            </span>
          </div>
        )}

        {!charityDetails.enableQuantity && (
          <div className="amounts-div">
            <div
              onClick={handleAmount}
              id={10}
              className={`amounts${parseFloat(amount) === 10 ? " active" : ""}`}
            >
              <h5>
                <span>{organizationDetails.currencySymbol} </span>
                10
              </h5>
            </div>

            <div
              onClick={handleAmount}
              id={25}
              className={`amounts${parseFloat(amount) === 25 ? " active" : ""}`}
            >
              <h5>
                <span>{organizationDetails.currencySymbol} </span>
                25
              </h5>
            </div>

            <div
              onClick={handleAmount}
              id={50}
              className={`amounts${parseFloat(amount) === 50 ? " active" : ""}`}
            >
              <h5>
                <span>{organizationDetails.currencySymbol} </span>
                50
              </h5>
            </div>

            <div
              onClick={handleAmount}
              id={100}
              className={`amounts${
                parseFloat(amount) === 100 ? " active" : ""
              }`}
            >
              <h5>
                <span>{organizationDetails.currencySymbol} </span>
                100
              </h5>
            </div>

            <div
              onClick={handleAmount}
              id={250}
              className={`amounts${
                parseFloat(amount) === 250 ? " active" : ""
              }`}
            >
              <h5>
                <span>{organizationDetails.currencySymbol} </span>
                250
              </h5>
            </div>

            <div
              className={`amounts${customAmountActive ? " active" : ""}`}
              onClick={handleCustomAmount}
            >
              <h5 className="custom-amount">
                {t("CampaignDetails.custom")} <br />
                {t("CampaignDetails.amount")}
              </h5>
            </div>
          </div>
        )}

        {/* continue btn */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: `${25}px`,
          }}
        >
          <button
            type="button"
            className="continue-btn"
            onClick={handleContinue}
            style={buttonStyle}
          >
            {t("CampaignDetails.continue")}
            <span>
              <Icon icon={arrows_right} size={28} />
            </span>
          </button>
        </div>
      </div>

      {/* indicators */}
      <div className="indicators-box">
        <span className="indicators" onClick={() => handleBackStep(step)} />
        <span className="indicators active" />
        <span className="indicators" onClick={handleContinue} />
        {!charityDetails.enableQuantity && (
          <>
            {parseFloat(charityDetails.fees) > 0 && (
              <span className="indicators" />
            )}
            <span className="indicators" />
          </>
        )}
      </div>

      {/* footer */}
      <div className="embedded-footer-box">
        <div>
          <span>
            <Icon icon={basic_lock} size={22} />
          </span>
          <span className="secure-donation">
            {t("OrganizationDetails.secure_donation")}
          </span>
        </div>

        <div className="powered-by">
          {t("Embed.powered_by")}
          <img src={logo} alt="logo" />
        </div>

        {lang === "en" ? (
          <button
            type="button"
            id="fr"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn embed"
          >
            française
          </button>
        ) : (
          <button
            type="button"
            id="en"
            onClick={(e) => handleLanguage(e.currentTarget.id)}
            className="change-lang-btn embed"
          >
            English
          </button>
        )}
      </div>
    </div>
  );
};

export default EmbeddedCharityDetails;
