/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MDBBtn } from "mdb-react-ui-kit";
import Icon from "react-icons-kit";
import { basic_lock } from "react-icons-kit/linea/basic_lock";
import logo from "../../images/logo.png";
import { useNavigate } from "react-router-dom";

export const SuccessMsgScreen = ({
  organizationDetails,
  setStep,
  amount,
  newAmount,
  howOften,
  recurringValue,
  specifyTime,
  fees,
  cardHolderName,
  cardHolderEmail,
  successMsg,
  paymentSuccess,
  lang,
  handleLanguage,
  paths,
  currentQuery,
  startDate,
  quantity,
  charityDetails,
}) => {
  // format date
  const formatDate = (dateObj) => {
    if (dateObj) {
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  // translation
  const { t } = useTranslation();

  // navigate
  const navigate = useNavigate();

  // if user cheats and directly come to this step, navigate the user
  useEffect(() => {
    if (!paymentSuccess) {
      setStep(0);
      if (currentQuery) {
        navigate(`/${paths[0]}/${paths[1]}${currentQuery}`, { replace: true });
      } else {
        navigate(`/${paths[0]}/${paths[1]}`, { replace: true });
      }
    }
  }, []);

  // start again
  const handleStartAgain = () => {
    if (currentQuery) {
      // Update the URL without navigating
      window.history.replaceState(
        null,
        "",
        `/${paths[0]}/${paths[1]}${currentQuery}`
      );
    } else {
      // Update the URL without navigating
      window.history.replaceState(null, "", `/${paths[0]}/${paths[1]}`);
    }
    // Reload the page
    window.location.reload();
  };

  return (
    <div className="home-content">
      <div className="embed-charity-details-container">
        {successMsg && (
          <div
            className="user-message"
            style={{ width: 100 + "%", borderRadius: 0 }}
          >
            {successMsg}
          </div>
        )}
        <main className="transaction-info-box">
          <div className="transaction-info">
            <h5>{t("DonationModal.cardholder_name")}</h5>
            <h5>{cardHolderName}</h5>
          </div>
          <div className="transaction-info">
            <h5>{t("DonationModal.email")}</h5>
            <h5>{cardHolderEmail}</h5>
          </div>
          <div className="transaction-info">
            <h5>
              {charityDetails.enableQuantity
                ? `${t("CampaignDetails.cost_per_unit")}`
                : `${t("DonationModal.amount")}`}
            </h5>
            <h5>
              {organizationDetails.currencySymbol}
              {charityDetails.enableQuantity
                ? `${charityDetails.minimumAmount}`
                : `${amount}`}
            </h5>
          </div>
          {parseFloat(charityDetails.fees) > 0 &&
            !charityDetails.enableQuantity && (
              <>
                <div className="transaction-info">
                  <h5>{t("DonationModal.fee")}</h5>
                  <h5>
                    {organizationDetails.currencySymbol}
                    {fees}
                  </h5>
                </div>
                <div className="transaction-info">
                  <h5>{t("DonationModal.total_amount")}</h5>
                  <h5>
                    {organizationDetails.currencySymbol}
                    {newAmount}
                  </h5>
                </div>
              </>
            )}
          {charityDetails.enableQuantity && (
            <>
              <div className="transaction-info">
                <h5>{t("CampaignDetails.quantity")}</h5>
                <h5>{quantity}</h5>
              </div>
              <div className="transaction-info">
                <h5>{t("DonationModal.total_amount")}</h5>
                <h5>
                  {organizationDetails.currencySymbol}
                  {newAmount}
                </h5>
              </div>
            </>
          )}
          <div className="transaction-info">
            <h5>{t("DonationModal.billing_period")}</h5>
            <h5>
              {howOften === "ONE-TIME" ? (
                <>{t("DonationModal.one_time")}</>
              ) : (
                <>
                  {howOften === "DAILY" ? (
                    <>{t("DonationModal.daily")}</>
                  ) : (
                    <>
                      {howOften === "WEEKLY" ? (
                        <>{t("DonationModal.weekly")}</>
                      ) : (
                        <>
                          {howOften === "BIWEEKLY" ? (
                            <>{t("DonationModal.biweekly")}</>
                          ) : (
                            <>
                              {howOften === "MONTHLY" && (
                                <>{t("DonationModal.monthly")}</>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </h5>
          </div>
          {howOften !== "ONE-TIME" && (
            <>
              {recurringValue && (
                <div className="transaction-info">
                  <h5>{t("DonationModal.no_of_recurring")}</h5>
                  {specifyTime ? (
                    <h5>{recurringValue}</h5>
                  ) : (
                    <h5>{t("DonationModal.until_you_specify")}</h5>
                  )}
                </div>
              )}
              <div className="transaction-info">
                <h5>{t("DonationModal.start_date")}</h5>
                <h5>{formatDate(startDate)}</h5>
              </div>
            </>
          )}
          <div className="transaction-info">
            <h5>{t("DonationModal.organization")}</h5>
            <h5>{organizationDetails.name}</h5>
          </div>
          <div className="transaction-info">
            <h5>{t("DonationModal.campaign")}</h5>
            <h5>{charityDetails.name}</h5>
          </div>
          <MDBBtn
            type="button"
            onClick={handleStartAgain}
            className="close-modal-btn"
          >
            {t("Embed.start_again")}
          </MDBBtn>
        </main>

        {/* footer */}
        <div className="embedded-footer-box">
          <div>
            <span>
              <Icon icon={basic_lock} size={22} />
            </span>
            <span className="secure-donation">
              {t("OrganizationDetails.secure_donation")}
            </span>
          </div>

          <div className="powered-by">
            {t("Embed.powered_by")}
            <img src={logo} alt="logo" />
          </div>

          {lang === "en" ? (
            <button
              type="button"
              id="fr"
              onClick={(e) => handleLanguage(e.currentTarget.id)}
              className="change-lang-btn embed"
            >
              française
            </button>
          ) : (
            <button
              type="button"
              id="en"
              onClick={(e) => handleLanguage(e.currentTarget.id)}
              className="change-lang-btn embed"
            >
              English
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessMsgScreen;
